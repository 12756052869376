import React from 'react';
import maps from '../data/maps'; // Import the maps data

const MapSelection = ({ onMapSelect }) => {
  return (
    <div style={{ width: '90%', margin: '0 auto' }}> {/* Center the grid and set width to 75% */}
      <div 
        className="map-grid"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))', // Responsive grid
          gap: '10px',
        }}
      >
        {maps.map((map) => (
          <div
            key={map.name}
            onClick={() => onMapSelect(map.name)}
            style={{
              cursor: 'pointer',
              backgroundImage: `url(${map.background})`, // Set the background image
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '150px', // Reduce the width of each map option
              height: '200px', // Increase the height of each map option
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            <p>{map.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MapSelection;