// src/data/maps.js
const maps = [
    { name: 'Customs', image: '/maps/Customs-map.png', background: '/mapimages/customs.png' },
    { name: 'Factory', image: '/maps/Factory-map.png', background: '/mapimages/factory.png' },
    { name: 'Woods', image: '/maps/Woods-map.png', background: '/mapimages/woods.png' },
    { name: 'Ground Zero', image: '/maps/Groundzero-map.png', background: '/mapimages/groundzero.png' },
    { name: 'Interchange', image: '/maps/Interchange-map.png', background: '/mapimages/interchange.png' },
    { name: 'Labs', image: '/maps/Labs-map.png', background: '/mapimages/labs.png' },
    { name: 'Lighthouse', image: '/maps/Lighthouse-map.png', background: '/mapimages/lighthouse.png' },
    { name: 'Reserve', image: '/maps/Reserve-map.png', background: '/mapimages/reserve.png' },
    { name: 'Shoreline', image: '/maps/Shoreline-map.png', background: '/mapimages/shoreline.png' },
    { name: 'Streets', image: '/maps/Streets-map.png', background: '/mapimages/streets.png' },
    // Add more maps as needed
];

export default maps;