import React, { useState } from 'react';
import Game from './components/Game';
import './App.css';

const App = () => {
  const [isGameStarted, setIsGameStarted] = useState(false);

  const startGame = () => {
    setIsGameStarted(true);
  };

  return (
    <div className={`App ${!isGameStarted ? 'landing-page' : ''}`}>
      {!isGameStarted ? (
        <div>
          <h1>Welcome to Tarkovguessr</h1>
          <div className="container">
            <button className="start-game-button" onClick={startGame}>Start Game</button>
          </div>
          <h3>Thank you to <a href="https://eftguessr.com" target="_blank" rel="noreferrer">EFTGuessr (no longer maintained)</a> for the original idea.</h3>
          <h3>Discord bot coming soon!</h3>
        </div>
      ) : (
        <Game />
      )}
      <footer className="footer">
        <p>© 2024 KCVI. All rights reserved.</p>
        <div className="donate-button-container">
        <button className="donate-button" onClick={() => window.open('https://donate.stripe.com/14k3fagX54gc8BG7ss', '_blank')}>
            Donate
          </button>
        </div>
      </footer>
    </div>
  );
};

export default App;