const screenshots = [
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/1.png',
      map: 'Customs',
      location: { x: 676, y: 332 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/2.png',
      map: 'Customs',
      location: { x: 668, y: 345 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/3.png',
      map: 'Customs',
      location: { x: 610, y: 383 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/4.png',
      map: 'Customs',
      location: { x: 562, y: 353 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/5.png',
      map: 'Customs',
      location: { x: 561, y: 327 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/6.png',
      map: 'Customs',
      location: { x: 635, y: 450 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/7.png',
      map: 'Customs',
      location: { x: 510, y: 454 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/8.png',
      map: 'Customs',
      location: { x: 449, y: 447 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/9.png',
      map: 'Customs',
      location: { x: 405, y: 445 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/10.png',
      map: 'Customs',
      location: { x: 383, y: 419 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/11.png',
      map: 'Customs',
      location: { x: 351, y: 383 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/12.png',
      map: 'Factory',
      location: { x: 100, y: 449 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/13.png',
      map: 'Factory',
      location: { x: 139, y: 405 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/14.png',
      map: 'Factory',
      location: { x: 311, y: 362 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/15.png',
      map: 'Factory',
      location: { x: 292, y: 321 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/16.png',
      map: 'Factory',
      location: { x: 221, y: 326 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/17.png',
      map: 'Factory',
      location: { x: 146, y: 381 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/18.png',
      map: 'Factory',
      location: { x: 146, y: 410 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/19.png',
      map: 'Factory',
      location: { x: 158, y: 464 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/20.png',
      map: 'Factory',
      location: { x: 110, y: 462 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/21.png',
      map: 'Woods',
      location: { x: 295, y: 160 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/22.png',
      map: 'Woods',
      location: { x: 287, y: 423 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/23.png',
      map: 'Woods',
      location: { x: 249, y: 443 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/24.png',
      map: 'Woods',
      location: { x: 239, y: 468 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/25.png',
      map: 'Woods',
      location: { x: 334, y: 572 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/26.png',
      map: 'Woods',
      location: { x: 352, y: 662 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/27.png',
      map: 'Interchange',
      location: { x: 383, y: 274 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/28.png',
      map: 'Interchange',
      location: { x: 467, y: 314 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/29.png',
      map: 'Interchange',
      location: { x: 520, y: 399 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/30.png',
      map: 'Interchange',
      location: { x: 585, y: 158 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/31.png',
      map: 'Interchange',
      location: { x: 633, y: 267 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/32.png',
      map: 'Interchange',
      location: { x: 660, y: 324 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/33.png',
      map: 'Interchange',
      location: { x: 1021, y: 276 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/34.png',
      map: 'Labs',
      location: { x: 480, y: 358 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/35.png',
      map: 'Labs',
      location: { x: 387, y: 358 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/36.png',
      map: 'Labs',
      location: { x: 868, y: 369 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/37.png',
      map: 'Labs',
      location: { x: 525, y: 307 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/38.png',
      map: 'Labs',
      location: { x: 401, y: 210 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/39.png',
      map: 'Reserve',
      location: { x: 186, y: 490 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/40.png',
      map: 'Reserve',
      location: { x: 583, y: 328 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/41.png',
      map: 'Reserve',
      location: { x: 620, y: 307 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/42.png',
      map: 'Reserve',
      location: { x: 646, y: 248 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/43.png',
      map: 'Reserve',
      location: { x: 700, y: 296 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/44.png',
      map: 'Reserve',
      location: { x: 763, y: 273 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/45.png',
      map: 'Reserve',
      location: { x: 783, y: 346 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/46.png',
      map: 'Reserve',
      location: { x: 286, y: 380 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/47.png',
      map: 'Reserve',
      location: { x: 265, y: 375 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/48.png',
      map: 'Reserve',
      location: { x: 233, y: 304 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/49.png',
      map: 'Reserve',
      location: { x: 224, y: 353 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/50.png',
      map: 'Reserve',
      location: { x: 179, y: 347 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/51.png',
      map: 'Shoreline',
      location: { x: 918, y: 259 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/52.png',
      map: 'Shoreline',
      location: { x: 824, y: 318 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/53.png',
      map: 'Shoreline',
      location: { x: 699, y: 423 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/54.png',
      map: 'Shoreline',
      location: { x: 603, y: 377 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/55.png',
      map: 'Shoreline',
      location: { x: 735, y: 320 },
    },
    {
      image: 'https://tarkovguessr.s3.eu-north-1.amazonaws.com/56.png',
      map: 'Shoreline',
      location: { x: 527, y: 154 },
    },
  ];
  
export default screenshots;