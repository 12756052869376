import React, { useState } from 'react';

const InteractiveMap = ({ mapImage, onLocationPinpoint, correctLocation }) => {
  const [pinLocation, setPinLocation] = useState(null);

  const handleClick = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    setPinLocation({ x, y });
    onLocationPinpoint({ x, y }); // Pass the pin location back to the parent component
  };

  return (
    <div style={{ position: 'relative' }}>
      <img
        src={mapImage}
        alt="Map"
        onClick={handleClick}
        style={{ cursor: 'pointer', maxWidth: '100%' }}
      />
      {pinLocation && (
        <div
          style={{
            position: 'absolute',
            left: pinLocation.x, // Center the pinpoint
            top: pinLocation.y,
            width: '5px',
            height: '5px',
            backgroundColor: 'red',
            borderRadius: '50%',
          }}
        />
      )}
    </div>
  );
};

export default InteractiveMap;