import React, { useState, useEffect } from 'react';
import screenshots from '../data/screenshots';
import InteractiveMap from './InteractiveMap';
import Modal from './Modal'; // Import the modal component
import MapSelection from './MapSelection'; // Import the MapSelection component
import maps from '../data/maps'; // Import the maps data

const Game = () => {
  const [currentScreenshot, setCurrentScreenshot] = useState(null);
  const [selectedMap, setSelectedMap] = useState(null); // Change to store the entire map object
  const [userGuess, setUserGuess] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [score, setScore] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);
  const [modalMessage, setModalMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [mapImage, setMapImage] = useState('');
  const maxQuestions = 10;

  useEffect(() => {
    if (questionCount < maxQuestions) {
      setRandomScreenshot();
    }
  }, [questionCount]);

  const setRandomScreenshot = () => {
    const randomIndex = Math.floor(Math.random() * screenshots.length);
    setCurrentScreenshot(screenshots[randomIndex]);
  };

  const handleMapSelection = (mapName) => {
    const selectedMapData = maps.find((map) => map.name === mapName);
    setSelectedMap(selectedMapData); // Store the entire map object
    setShowMap(true);
    if (selectedMapData) {
        setMapImage(selectedMapData.image); // Store the correct image path
    }
  };

  const handleLocationPinpoint = (location) => {
    setUserGuess(location);
  };

  const handleSubmitGuess = () => {
    if (userGuess && currentScreenshot) {
      const distance = calculateDistance(userGuess, currentScreenshot.location);
      const isCorrectMap = selectedMap.name === currentScreenshot.map; // Ensure the correct map check
  
      console.log(`User Guess: ${JSON.stringify(userGuess)}`); // Log user guess
      console.log(`Actual Location: ${JSON.stringify(currentScreenshot.location)}`); // Log actual location
      console.log(`Distance: ${distance}`); // Log distance for debugging
      let scorePoints = 0;
  
      // Check the distance to assign points
      if (distance < 10) {
        scorePoints = 10;
      } else if (distance < 10) {
        scorePoints = 9;
      } else if (distance < 20) {
        scorePoints = 8;
      } else if (distance < 30) {
        scorePoints = 7;
      } else if (distance < 40) {
        scorePoints = 6;
      } else if (distance < 50) {
        scorePoints = 5;
      } else if (distance < 100) {
        scorePoints = 4;
      } else if (distance < 110) {
        scorePoints = 3;
      } else if (distance < 120) {
        scorePoints = 2;
      } else {
        scorePoints = 1; // For any distance larger than 800
      }
  
      if (!isCorrectMap) {
        setModalMessage("Incorrect map selection! You scored 0 points.");
      } else {
        setScore((prev) => prev + scorePoints);
        setModalMessage(`You scored ${scorePoints} points!`);
      }
  
      setIsModalOpen(true); // Open the modal
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUserGuess(null);
    setSelectedMap(null); // Reset selected map
    setShowMap(false);
    setQuestionCount((prev) => prev + 1);
  };

  const calculateDistance = (guess, actual) => {
    const dx = guess.x - actual.x;
    const dy = guess.y - actual.y;
    return Math.sqrt(dx * dx + dy * dy);
  };

  return (
    <div>
      {questionCount < maxQuestions ? (
        <>
          <h2>Score: {score}</h2> {/* Display Score */}
          <h3>Question: {questionCount + 1} / {maxQuestions}</h3> {/* Display Question Counter */}

          {currentScreenshot && (
            <div>
              <div className="screenshot-container">
                <img
                  src={currentScreenshot.image}
                  alt="Random Screenshot"
                  className="screenshot"
                />
              </div>

              {!showMap && (  /* Hide map options after map selection */
                <>
                  <h3>Select the map:</h3>
                  <MapSelection onMapSelect={handleMapSelection} /> {/* Use MapSelection here */}
                </>
              )}
            </div>
          )}

          {showMap && (
            <div>
              <h3>Selected Map: {selectedMap.name}</h3>
              <div className="map-container">
              <InteractiveMap
                  mapImage={mapImage} // Use the state with the correct image path
                  correctLocation={currentScreenshot.location}
                  onLocationPinpoint={handleLocationPinpoint}
                  onSubmitGuess={handleSubmitGuess}
              />
              </div>
              <div className="submit-button-container">
                <button onClick={handleSubmitGuess}>Submit Guess</button>
              </div>
            </div>
          )}

          <Modal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            message={modalMessage}
          />
        </>
      ) : (
        <div className="final-score-screen">
          <h2>Game Over!</h2>
          <h3>Your Final Score: {score}</h3>
          <button className="start-game-button" onClick={() => window.location.reload()}>Play Again</button>
        </div>
      )}
    </div>
  );
};

export default Game;